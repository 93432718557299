/* Pray.css */
.pray-section {
    margin-top: 2rem;
    padding: 4rem 2rem;
    min-height: 600px;
    background-color: #ceecf3;
    text-align: center;
  }
  
  .pray-images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .pray-images img {
    width: 250px;
    height: 350px;
    object-fit: cover;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .pray-images img:hover {
    transform: scale(1.05);
  }
  
  /* Lightbox Styling */
  .lightbox {
    display: none; /* Initially hidden */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Background overlay */
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lightbox.active {
    display: flex;
  }
  
  .lightbox-image {
    max-width: 80%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  /* Arrows for navigation */
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }
  
  .arrow.left {
    left: 10px;
  }
  
  .arrow.right {
    right: 10px;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .pray-images img {
        margin-top: 16rem;
      width: 100%;
      height: auto;
      margin-bottom: 1.5rem;
    }
  
    .lightbox-image {
      max-width: 90%;
    }
  }
  