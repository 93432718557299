/* Header.css */


.header-section {
  margin-top: 4rem;
  position: relative;
  width: 100%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cover-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.header-content {
  color: white;
  text-align: center;
  z-index: 1;
}

.header-content h2 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.header-content p {
  font-size: 1.25rem;
  margin: 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .header-content h2 {
    font-size: 2rem;
  }

  .header-content p {
    font-size: 1rem;
  }
}
