/* About.css */
.about-section {
  margin-top: 2rem;
  padding: 4rem 2rem;
}

.decorated-header {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .about-section {
    padding: 2rem 1rem;
  }

  .decorated-header {
    font-size: 1.5rem;
  }
}
