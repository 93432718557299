/* Contact.css */
.contact-section {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
}

.contact-section h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-details {
  flex: 1;
  margin-right: 2rem;
}

.contact-details h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.contact-details p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.contact-details a {
  color: #007bff;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

.contact-map {
  flex: 1;
  text-align: center;
}

.contact-map img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.contact-map img:hover {
  transform: scale(1.05);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    text-align: center;
  }

  .contact-details {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .contact-map img {
    max-width: 100%;
  }
}
