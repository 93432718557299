/* App.css */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
