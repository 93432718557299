/* Navbar.css */
.nav-bar {
  display: flex;
  justify-content: space-around;
  padding: 1.5rem;

  background-color: rgba(248, 249, 250, 0.9);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav-bar li {
  margin: 0 1rem;
}

.nav-bar a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.nav-bar a:hover {
  color: #007BFF;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .nav-bar {
    flex-direction: column;
    padding: 2rem;
  }

  .nav-bar li {
    margin: 0.5rem 0;
  }
}
